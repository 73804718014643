<template>
  <div class="list-info">
    <top-bar :title="'违章建筑管理'" :left="true"></top-bar>
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="illegalShow=!illegalShow" :style="{color:illegalShow?'#387FF5':'#666666' }">违建时间
            <img :src="require(`@/assets/img/${illegalShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="disposeShow=!disposeShow" :style="{color:disposeShow?'#387FF5':'#666666' }">处置时间
            <img :src="require(`@/assets/img/${disposeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="moreShow = !moreShow" :style="{color:moreShow?'#387FF5':'#666666' }">更多
            <img :src="require(`@/assets/img/${moreShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="illegalShow">
      <van-row>
        <van-col span="8" @click="illegalBeginDateShow = !illegalBeginDateShow"><span>{{selectDate.illegalBeginDate == ''?'最早':selectDate.illegalBeginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="illegalEndDateShow = !illegalEndDateShow"><span>{{selectDate.illegalEndDate== ''?'至今':selectDate.illegalEndDate}}</span></van-col>
        <van-col span="3" @click="dateClose1"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate1"><span>确定</span></van-col>
      </van-row>
    </div>

    <van-popup v-model="illegalBeginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim1" @cancel="illegalBeginDateShow = false"  type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="illegalEndDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim1" @cancel="illegalEndDateShow = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(new Date().getFullYear()+5, 10, 1)"/>
    </van-popup>

    <div class="dateBar" v-show="disposeShow">
      <van-row>
        <van-col span="8" @click="disposeBeginDateShow = !disposeBeginDateShow"><span>{{selectDate.disposeBeginDate == ''?'最早':selectDate.disposeBeginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="disposeEndDateShow = !disposeEndDateShow"><span>{{selectDate.disposeEndDate== ''?'至今':selectDate.disposeEndDate}}</span></van-col>
        <van-col span="3" @click="dateClose2"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate2"><span>确定</span></van-col>
      </van-row>
    </div>

    <van-popup v-model="disposeBeginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim2" @cancel="disposeBeginDateShow = false"  type="date"
                           :formatter="formatDate" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="disposeEndDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim2" @cancel="disposeEndDateShow = false" type="date" :formatter="formatDate"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(new Date().getFullYear()+5, 10, 1)"/>
    </van-popup>
<!--更多-->
    <van-popup v-model="moreShow" position="right" :style="{ width: '90%', height: '100%'}" >
      <div style="margin-bottom: 50px;">
        <van-cell title="处理方式">
          <span style="color: #387FF5">{{ show.handleType== ''?'处理方式':show.handleType }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="value in handleTypeList" :key="value.value">
            <van-tag size="large" @click="handleTypeFn(value)" :class="value.value === selectDate.handleType?'checkedTag':'commonTag'">{{ value.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
        <van-cell title="主体类别">
          <span style="color: #387FF5">{{ show.builderType== ''?'主体类别':show.builderType }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="item in builderTypeList" :key="item.value">
            <van-tag size="large" @click="builderTypeFn(item)" :class="item.value === selectDate.builderType?'checkedTag':'commonTag'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
        <van-cell title="违章类型">
          <span style="color: #387FF5">{{ show.builtType== ''?'违章类型':show.builtType }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="item in builtTypeList" :key="item.value">
            <van-tag size="large" @click="builtTypeFn(item)" :class="item.value === selectDate.builtType?'checkedTag':'commonTag'">{{ item.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
      </div>
      <van-row >
        <van-col span="12">
          <van-button block @click="moreCancel">重置</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" block @click="moreConfirm">确定</van-button>
        </van-col>
      </van-row>
    </van-popup>


    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->

        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList && dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.builderName }}
              </div>
              <div class="title-right">
                <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
            </div>
            <div class="content">
              <van-image :src="item.fileList.length!==0?item.fileList[0].filePath:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.address}}</div>
                <div class="right-text" style="right:0">{{item.handleTypeStr}}</div>
                <div class="bottom-text" style="color:#387FF5">{{item.builtTypeStr}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="bottom-icon"/>
            </div>
            <div class="content-bottom">
              <van-row>
                <van-col :span="12">
                  违建时间: {{item.builtDate}}
                </van-col>
                <van-col :span="12" v-if="item.handleDate">
                  处置时间: {{item.handleDate}}
                </van-col>
              </van-row>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->

    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
import {newDate} from '@/utils/utils'
export default {
  components :{
    topBar
  },
  data() {
    return {
      loading: false,
      finished: false,
      illegalShow: false,
      illegalBeginDateShow: false,
      illegalEndDateShow: false,
      disposeShow: false,
      disposeBeginDateShow: false,
      disposeEndDateShow: false,
      moreShow:false,
      pullLoading:false,
      page: 0,
      limit: 10,
      searchValue: '',
      show:{
        handleType: '',   //处理方式
        builderType: '',     //违章主体类型
        builtType: '',      //违章类型
      },
      selectDate: {
        illegalBeginDate: '',
        illegalEndDate: '',
        disposeBeginDate: '',
        disposeEndDate: '',
        handleType: '',   //处理方式
        builderType: '',     //违章主体类型
        builtType: '',      //违章类型
        orgId:this.$orgId
      },
      totalCount: 0,
      dataList: [],
      handleTypeList:[
        { label: '未处置', value: '0' }, { label: '拆除', value: '1' }, { label: '整治', value: '2' }
      ],
      builderTypeList:[{ label: '居民房屋', value: '1' }, { label: '商户', value: '2' }],
      builtTypeList:  [
        { label: '阳光房', value: '1', },
        { label: '天井披棚', value: '2' },
        { label: '乱搭乱建', value: '3' },
        { label: '拆迁区域违建', value: '4' },
      ]
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      this.$router.push({path: '/illegalBuilding-add', query: {id: id}})
    },
    getDataList () {
      this.page++
      // this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/illegalbuilding/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,          //当前页码
          limit: this.limit,        //每页展示的记录数
          // name: dataForm.searchValue, // 搜索框内容,以名字作为查询条件
          illegalStartTime: this.selectDate.illegalBeginDate,     //违建日期
          illegalEndDates: this.selectDate.illegalEndDate,
          disposeStartTime: this.selectDate.disposeBeginDate,     //处置日期
          disposeEndDates: this.selectDate.disposeEndDate,
          handleType: this.selectDate.handleType,   //处理方式
          builderType: this.selectDate.builderType,     //违章主体类型
          builtType: this.selectDate.builtType,      //违章类型
          orgId:this.selectDate.orgId
        })
      }).then(({data})=> {
        this.pullLoading =false
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // let dataList = data.page.list
          if (data.page.list && data.page.list.length>0){
            data.page.list.map(item => {
              if (item.fileList && item.fileList.length>0) {
                item.fileList[0].filePath = getImageStream(item.fileList[0].filePath)
              }
            })
          }

          this.dataList = this.dataList.concat(data.page.list)

          this.$toast.clear()
        }
        // 加载状态结束
        this.loading = false;
        this.$toast.clear()
      },err=> {
        // 加载状态结束
        this.loading = false;
        this.$toast.clear()})
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    beginDateConfim1 (value) {
      let d = newDate(this.selectDate.illegalEndDate)
      if (this.selectDate.illegalEndDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.illegalBeginDate = formatterDate(value)
      this.illegalBeginDateShow = false
    },
    endDateConfim1 (value) {
      let d = newDate(this.selectDate.illegalBeginDate)
      if (this.selectDate.illegalBeginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.illegalEndDate = formatterDate(value)
      this.illegalEndDateShow = false
    },
    changeDate1 () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    beginDateConfim2 (value) {
      let d = newDate(this.selectDate.disposeEndDate)
      if (this.selectDate.disposeEndDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.disposeBeginDate = formatterDate(value)
      this.disposeBeginDateShow = false
    },
    endDateConfim2 (value) {
      let d = newDate(this.selectDate.disposeBeginDate)
      if (this.selectDate.disposeBeginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.disposeEndDate = formatterDate(value)
      this.disposeEndDateShow = false
    },
    changeDate2 () {
      this.dataList = []
      this.page = 0
      this.getDataList()
    },
    dateClose1 () {
      this.selectDate.illegalBeginDate = ''
      this.selectDate.illegalEndDate = ''
    },
    dateClose2 () {
      this.selectDate.disposeBeginDate = ''
      this.selectDate.disposeEndDate = ''
    },
    handleTypeFn(val){
      this.show.handleType = val.label
      this.selectDate.handleType = val.value
    },
    builderTypeFn(val){
      this.show.builderType = val.label
      this.selectDate.builderType = val.value
    },
    builtTypeFn(val){
      this.show.builtType = val.label
      this.selectDate.builtType = val.value
    },
    moreCancel() {
      //重置
      this.selectDate.handleType = ''
      this.selectDate.builderType = ''
      this.selectDate.builtType = ''
      this.show.handleType = ''
      this.show.builderType = ''
      this.show.builtType = ''
      this.onPullDownRefresh()
    },
    moreConfirm() {
      this.moreShow = false
      this.onPullDownRefresh()
    },
    nofind(){
      var img=event.srcElement;
      img.src=getImageStream(null);

    //也能是：img.src="${pageContext.request.contextPath}/static/img/small-sina.ico";

      img.onerror=null;// 控制不要一直跳动
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/illegalBuilding-add')
    }
    },
    created () {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.userId = this.$globalData.userInfo.userId
      this.getDataList()
    }
}
</script>
<style lang="scss" scoped>
.replenish{
  padding-bottom: 15px;
}
.van-popup {
  .van-tag {
    text-align: center;
    display: block;
    width: 190px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
}
.checkedTag {
  background-color: rgba(0, 122, 255, 0.1);
  color: #387ff5;
  border: 1px solid #387ff5;
}
.commonTag {
  background-color: #f0f2f5;
  color: #333;
}
</style>
